import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Author from '../assets/author.png';
import Educator from '../assets/educator.png';
import Knowledge from '../assets/knowledge.png';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  transition: 'box-shadow 0.3s ease',
  boxShadow: '0 4px 8px rgba(31, 0, 52, 0.8)',
}));

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  opacity: 0,
  transition: 'opacity 0.3s ease',
  fontFamily: 'var(--font-body)',
  fontSize: '18px',
  textAlign: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  borderRadius: '4px',
  '&:hover': {
    opacity: 1,
  },
});


const ForWhomVariant = () => {
  return (
    <Box sx={{ width: '100%', padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{paddingBottom: "40px", marginTop: "0", textAlign: "center"}}>Dla kogo jest oferta AStep_Lab?</h2>

      <div style={{ display: 'flex', marginBottom: '30px', width: '80%', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={Author} alt="Twórcy treści cyfrowych" style={{ maxWidth: '40%', borderRadius: '4px' }} />
        <Item style={{ width: '55%' }}>
          <h3 style={{ fontFamily: 'var(--font-body)', color: 'var(--color-main)' }}>Twórcy treści cyfrowych</h3>
          <p>
            Jeśli tworzysz e-booki, kursy online, prezentacje lub blogi i chcesz zwiększyć swoją produktywność, 
            ta oferta jest dla Ciebie.
          </p>
        </Item>
      </div>

      <div style={{ display: 'flex', marginBottom: '30px', width: '80%', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={Educator} alt="Edukatorzy" style={{ maxWidth: '40%', borderRadius: '4px' }} />
        <Item style={{ width: '55%' }}>
          <h3 style={{ fontFamily: 'var(--font-body)', color: 'var(--color-main)' }}>Edukatorzy</h3>
          <p>
            Szukasz nowoczesnych rozwiązań do dzielenia się wiedzą i automatyzacji procesów twórczych? 
            Oferta AStep_Lab pomoże Ci osiągnąć te cele.
          </p>
        </Item>
      </div>

      <div style={{ display: 'flex', marginBottom: '30px', width: '80%', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={Knowledge} alt="Każdy, kto chce zmonetyzować swoją wiedzę" style={{ maxWidth: '40%', borderRadius: '4px' }} />
        <Item style={{ width: '55%' }}>
          <h3 style={{ fontFamily: 'var(--font-body)', color: 'var(--color-main)' }}>Chcący zmonetyzować wiedzę</h3>
          <p>
            Jeśli planujesz tworzenie produktów cyfrowych i chcesz to robić efektywnie, bez zbędnych kosztów, 
            to jesteś w dobrym miejscu.
          </p>
        </Item>
      </div>
    </Box>
  );
};


const RowAndColumnSpacing = () => {
  return (
    <Box sx={{ width: '100%', padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{paddingBottom: "40px", marginTop: "0", textAlign: "center"}}>Dla kogo jest oferta AStep_Lab?</h2>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      
        <Grid item xs={12} sm={4}>
          <Item>
            <h3 style={{ fontFamily: 'var(--font-body)', color: 'var(--color-main)' }}>Twórcy treści cyfrowych</h3>
            <div style={{ position: 'relative' }}>
              <img src={Author} alt="Twórcy treści cyfrowych" style={{ maxWidth: '100%', borderRadius: '4px' }} />
              <Overlay>
                <p>
                  Jeśli tworzysz e-booki, kursy online, prezentacje lub blogi i chcesz zwiększyć swoją produktywność, 
                  ta oferta jest dla Ciebie.
                </p>
              </Overlay>
            </div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Item>
            <h3 style={{ fontFamily: 'var(--font-body)', color: 'var(--color-main)' }}>Edukatorzy</h3>
            <div style={{ position: 'relative' }}>
              <img src={Educator} alt="Edukatorzy" style={{ maxWidth: '100%', borderRadius: '4px' }} />
              <Overlay>
                <p>
                  Szukasz nowoczesnych rozwiązań do dzielenia się wiedzą i automatyzacji procesów twórczych? 
                  Oferta AStep_Lab pomoże Ci osiągnąć te cele.
                </p>
              </Overlay>
            </div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Item>
            <h3 style={{ fontFamily: 'var(--font-body)', color: 'var(--color-main)' }}>Chcący zmonetyzować wiedzę</h3>
            <div style={{ position: 'relative' }}>
              <img src={Knowledge} alt="Każdy, kto chce zmonetyzować swoją wiedzę" style={{ maxWidth: '100%', borderRadius: '4px' }} />
              <Overlay>
                <p>
                  Jeśli planujesz tworzenie produktów cyfrowych i chcesz to robić efektywnie, bez zbędnych kosztów, 
                  to jesteś w dobrym miejscu.
                </p>
              </Overlay>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};


export const ForWhom = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return windowWidth >= 600 && windowWidth <= 1000 ? <ForWhomVariant /> : <RowAndColumnSpacing />;
};
