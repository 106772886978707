import './App.css';
import { ResponsiveAppBar } from './components/Header';
import { Hero } from './components/Hero';
import { ForWhom } from './components/ForWhom';
import { Offer } from './components/Offer';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { CookieConsent } from './components/CookieConsent';

function App() {

  return (
    <div className="canvas"> 
      <ResponsiveAppBar/>
      <Hero/>
      <ForWhom/>
      <Offer/>
      <Contact/>
      <Footer/>
      <CookieConsent />
    </div>
  );
}

export default App;
