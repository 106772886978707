import React from 'react';
import Paper from '@mui/material/Paper';
import { Footer } from "../components/Footer";
import { ResponsiveAppBarLight } from "./header-light";

export function TermsAndConditions() {
  return (
    <React.Fragment>
      <>
      <ResponsiveAppBarLight/>
      <div className="TermsAndConditions">
        <Paper className="text">
          <h2>Regulamin Sprzedaży AStep_Lab</h2>

          <h3>§ 1 Wstęp</h3>
          <ol>
            <li>
              Regulamin określa ogólne zasady dotyczące zakupu i korzystania z Produktów oferowanych przez Sprzedawcę, 
              w tym sposób zawarcia umowy, formy rozliczenia, zasady reklamacji, prawa autorskie, dane osobowe.
            </li>
            <li>
              Właścicielem Produktów oferowanych za pośrednictwem stron: <span> </span> 
              <a href="https://kursy.inmyailab.pl" target="_blank" rel="noopener noreferrer">kursy.inmyailab.pl</a>,<span> </span> 
              <a href="https://asteplab.pl" target="_blank" rel="noopener noreferrer">asteplab.pl</a> lub innych serwisów 
              firmowanych marką AStep_Lab jest AStep_Lab Anna Stępień, ul. Dąbska 18K/80, 31-572 Kraków, NIP: 6631713445, REGON: 260414237.
            </li>
            <li>
              Przed dokonaniem zakupu należy uważnie przeczytać poniższy Regulamin. Klient, w związku 
              z korzystaniem z Serwisu oraz wraz z momentem podjęcia czynności zmierzających do nabycia Produktu, 
              zobowiązany jest do przestrzegania postanowień niniejszego Regulaminu. Składając zamówienie w Serwisie Klient oświadcza, 
              że zapoznał się z niniejszym Regulaminem i akceptuje jego postanowienia, a także że zapoznał się i akceptuje 
              postanowienia dot. przetwarzania danych osobowych umieszczone w <a href="https://kursy.inmyailab.pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">Polityce Prywatności</a>.
            </li>
          </ol>

          <h3>§ 2 Definicje</h3>
<p>Na potrzeby niniejszego regulaminu przyjmuje się znaczenie pojęć:</p>
<ol>
  <li><span>Regulamin </span>– niniejszy dokument określający zasady sprzedaży Produktów poprzez Serwis;</li>
  <li><span>Klient </span>– osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości;</li>
  <li><span>Konto Klienta </span>– indywidualne, elektroniczne konto aktywowane dla Klienta w celu umożliwienia korzystania przez niego z Produktów;</li>
  <li><span>Konsument </span>– Klient jako osoba fizyczna dokonująca czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową albo osoba fizyczna zawierająca Umowę bezpośrednio związaną z jej działalnością gospodarczą, która nie posiada dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej;</li>
  <li><span>Polityka Prywatności </span>– zbiór zasad i informacji odnoszących się do przetwarzania danych osobowych osób korzystających z Serwisu oraz nabywających Produkty od Sprzedawcy, dostępny do zapoznania się pod adresem: <a href="https://asteplab.pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">https://asteplab.pl/polityka-prywatnosci</a>;</li>
  <li><span>Serwis </span>– strona internetowa <a href="https://kursy.inmyailab.pl" target="_blank" rel="noopener noreferrer">kursy.inmyailab.pl</a> lub inna posługująca się marką AStep_Lab;</li>
  <li><span>Produkt </span>– produkty cyfrowe, a także usługa świadczona drogą elektroniczną przez Sprzedawcę na rzecz Klienta polegająca na umożliwieniu Klientowi dostępu do platformy e-learningowej zawierającej treści cyfrowe w postaci kursów;</li>
  <li><span>Sprzedawca </span>– AStep_Lab Anna Stępień, ul. Dąbska 18K/80, 31-572 Kraków, NIP: 6631713445, REGON: 260414237. Kontakt ze Sprzedawcą możliwy jest za pomocą formularza, dostępnego na stronie: <a href="https://asteplab.pl" target="_blank" rel="noopener noreferrer">https://asteplab.pl</a> lub pod adresem poczty elektronicznej: <a href="mailto:anna.stepien@asteplab.pl">anna.stepien@asteplab.pl</a>;</li>
  <li><span>Umowa </span>– stosunek zobowiązaniowy między Sprzedawcą a Klientem w związku z nabyciem Produktu oferowanego w Serwisie.</li>
  <li><span>Zamówienie </span>– oświadczenie woli Klienta zmierzające do zawarcia Umowy, stanowiące ofertę zawarcia Umowy, określające w szczególności rodzaj i ilość Produktu.</li>
</ol>

          <h3>§ 3 Zawarcie Umowy</h3>
          <ol>
            <li>
              W celu złożenia Zamówienia w Sklepie należy dokonać wyboru Produktów, podejmując kolejne czynności techniczne na podstawie wyświetlanych Klientowi komunikatów i informacji zawartych w Serwisie.
            </li>
            <li>
              Klient w celu zakupu Produktów zbiera je do tzw. koszyka. Koszyk to element Serwisu, gdzie Klient przenosi Produkty, które zamierza zakupić. Klient w każdej chwili może obejrzeć zawartość koszyka, dodać bądź usunąć Produkty, a także od razu przejść do procesu złożenia Zamówienia.
            </li>
            <li>
              W toku procesu Zamówienia Klient precyzuje szczegóły takie jak: dane do Zamówienia, dane do rozliczenia, sposób płatności i inne.
            </li>
            <li>W toku procesu Zamówienia następuje utworzenie własnego Konta Klienta.</li>
            <li>Dla wybranych Produktów założenie Konta Klienta może być obowiązkowe dla prawidłowego świadczenia usług, dostawy Produktu.</li>
            <li>
              Podczas rejestracji Konta Klient zobowiązany jest podać unikalne i znane tylko sobie hasło. Proces rejestracji jest szyfrowany odpowiednim protokołem.
            </li>
            <li>
              Klient jest odpowiedzialny za udostępnienie swojego hasła osobom trzecim oraz ponosi wszelkie ewentualne konsekwencje finansowe i prawne takiego udostępnienia.
            </li>
            <li>Zamówienia na Produkty przyjmowane są wyłącznie drogą elektroniczną poprzez złożenie Zamówienia z wykorzystaniem Serwisu.</li>
            <li>Warunkiem niezbędnym do złożenia Zamówienia jest prawidłowe wypełnienie formularza Zamówienia. Zamówienie z błędnie wypełnionym formularzem może nie zostać zrealizowane.</li>
            <li>Wypełniając formularz Klient ma obowiązek podania prawidłowych danych osobowych.</li>
            <li>
              Podany adres e-mail oraz numer telefonu kontaktowego mogą zostać wykorzystane jedynie do kontaktu w procesie realizacji Zamówienia, chyba że Klient wyrazi zgodę na kontakt w innych celach (np. newsletter).
            </li>
            <li>
              Klient przed akceptacją Zamówienia zostanie poinformowany o:
              <ul>
                <li>głównych cechach Produktu;</li>
                <li>łącznej cenie lub wynagrodzeniu za Produkt wraz z podatkami, a także opłatach za transport, dostarczenie, usługi pocztowe oraz o innych kosztach – o ile występują;</li>
                <li>warunkach umownych sprzedaży, poprzez odesłanie wprost do Regulaminu przed złożeniem Zamówienia.</li>
              </ul>
            </li>
            <li>Klient wyraża wolę zawarcia Umowy poprzez naciśnięcie przycisku „zamawiam i płacę” lub równoważnego.</li>
            <li>Złożenie Zamówienia nie jest równoważne z zawarciem Umowy, lecz stanowi złożenie oferty zawarcia takiej Umowy.</li>
            <li>
              Umowa zostaje zawarta w chwili przyjęcia Zamówienia do realizacji przez Sprzedawcę, o czym Klient jest informowany poprzez wiadomość potwierdzającą dokonanie zakupu.
            </li>
            <li>Maksymalny możliwy czas realizacji zamówienia to 14 dni roboczych.</li>
            <li>
              W przypadku niedostępności części Produktów objętych Zamówieniem Klient zostanie poinformowany o stanie Zamówienia i podejmie decyzję o sposobie jego realizacji (częściowa realizacja lub anulowanie całości Zamówienia).
            </li>
            <li>
              Do sprzedaży promocyjnej oraz wyprzedaży może być przeznaczona ograniczona liczba Produktów. Realizacja Zamówień następuje według kolejności wpływania potwierdzonych Zamówień na te Produkty, aż do wyczerpania się zapasów objętych tą formą sprzedaży.
            </li>
            <li>
              Do zawarcia Umowy dojdzie również na wypadek udostępnienia Klientowi przez Sprzedawcę treści cyfrowych w zamian za przekazanie danych osobowych Klienta (najczęściej poprzez zapis na newsletter) na poczet wskazanego uprzednio celu przetwarzania. Umowę tego typu uważa się za zawartą z chwilą udostępnienia danych. W takiej sytuacji postanowienia Regulaminu będą mieć analogiczne zastosowanie jak do Umowy, w której zapłata za Produkt następuje w pieniądzu. Na wypadek gdyby Klient chciał nabyć treść cyfrową, lecz nie życzył sobie udostępnienia danych osobowych w zamian za treści cyfrowe, Sprzedawca zaprasza do kontaktu bezpośredniego w celu ustalenia ceny. 
            </li>
          </ol>

          <h3>§ 4 Ceny i płatności</h3>
          <ol>
            <li>Klient wybiera i opłaca Produkt zgodnie z obowiązującymi w Serwisie cenami i własnymi wyborami.</li>
            <li>
              Dostęp do Produktu w postaci kursu może być ograniczony czasowo, zgodnie z informacjami zawartymi w opisie bądź nieograniczony czasowo. Sprzedawca może przewidzieć darmowy okres próbny.
            </li>
            <li>
              Jeżeli po upływie wskazanego okresu czasu Klient nie opłaci dostępu do Produktu, dostęp do Produktu zostaje zablokowany a Umowa rozwiązana.
            </li>
            <li>
              Sprzedawca zastrzega sobie prawo do wprowadzania oraz odwoływania ofert, promocji oraz do zmiany cen w Serwisie. Z zastrzeżeniem, że powyższe nie będzie powodowało negatywnych zmian, uszczerbku dla praw nabytych przez Klienta, w tym w szczególności warunków umów zawartych przed dokonaniem zmiany.
            </li>
            <li>
              Dostęp do Produktu może zostać wykupiony w postaci biletu na jednorazowe przyszłe wydarzenie (np. szkolenie on-line w czasie rzeczywistym/live).
            </li>
            <li>Ceny zamieszczone w Serwisie są podane w polskich złotych i są cenami brutto.</li>
            <li>
              Płatności elektroniczne w Serwisie realizowane są za pośrednictwem: <a href="https://www.payu.pl" target="_blank" rel="noopener noreferrer">PayU</a> – więcej informacji nt. serwisu i przetwarzania danych osobowych znaleźć można pod adresem: <a href="https://www.payu.pl" target="_blank" rel="noopener noreferrer">https://www.payu.pl</a>.
            </li>
            <li>
              Zamówienia na Produkty realizowane są po otrzymaniu płatności poprzez wysyłkę Produktu w formie cyfrowej lub instrukcji skorzystania z Produktu w wiadomości email. Przystąpienie do dostarczenia Produktu następuje po otrzymaniu płatności, do 24 godzin od chwili otrzymania płatności. Dostawa Produktów jest darmowa.
            </li>
            <li>
              Jeżeli Klient poprosił o wystawienie faktury, zostanie ona doręczona drogą elektroniczną, na adres e-mail podany w formularzu Zamówienia, chyba że sprzeciwi się temu Klient.
            </li>
            <li>
              Sprzedawca w sytuacji stosowania obniżek cen zobowiązuje się wskazywać obok obniżonej ceny danego Produktu także jego najniższą cenę obowiązującą w okresie ostatnich 30 dni przed dniem wprowadzenia obniżki.
            </li>
          </ol>

          <h3>§ 5 Prawa autorskie</h3>
          <ol>
            <li>
              Produkt w postaci kursu może przybrać postać nagrań video, audio, prezentacji, szkoleń w czasie rzeczywistym (tzw. streaming, live webinar) lub plików pdf.
            </li>
            <li>
              Treści zamieszczone w Serwisie oraz w Produkcie mogą stanowić utwory w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych, do których prawa autorskie przysługują Sprzedawcy i podlegają ochronie.
            </li>
            <li>
              Sprzedawca zabrania ich komercyjnego wykorzystania, odsprzedaży czy powielania i udostępniania innym osobom bez jego zgody.
            </li>
            <li>
              Sprzedawca informuje Klienta, że dalsze rozpowszechnianie treści objętych prawami autorskimi przez Klienta bez zgody Sprzedawcy, za wyjątkiem korzystania z treści w ramach dozwolonego użytku osobistego, stanowi naruszenie praw autorskich przysługujących Sprzedawcy i może skutkować odpowiedzialnością cywilną lub karną.
            </li>
            <li>
              Z wyjątkiem wskazanych materiałów przez Sprzedającego, Klientowi nie przysługuje prawo pobrania – zwielokrotnienia trwałego materiałów i oprogramowania znajdującego się w Serwisie, ani w pełnej postaci ani w częściach.
            </li>
            <li>Klient nie nabywa jakichkolwiek praw do oprogramowania znajdującego się w Serwisie.</li>
          </ol>

          <h3>§ 6 Prawo odstąpienia od Umowy przez Konsumenta</h3>
          <ol>
            <li>
              Konsument może zrezygnować w drodze odstąpienia od Umowy z Produktu kupionego w Serwisie bez podania przyczyny w ciągu 14 dni od daty jego otrzymania. Do zachowania tego terminu wystarczy wysłanie oświadczenia o odstąpieniu na adres siedziby Sprzedawcy lub na adres e-mail podany w Regulaminie.
            </li>
            <li>W przypadku odstąpienia od Umowy zawartej na odległość, Umowa jest uważana za niezawartą.</li>
            <li>
              W razie odstąpienia od zawartej Umowy Konsument zobowiązany jest zwrócić Produkt najpóźniej w terminie 14 dni od dnia, w którym od Umowy odstąpił, a w sytuacji Produktu opartego na udzielonym dostępie do platformy, Sprzedawca zablokuje dalszy dostęp z chwilą złożenia oświadczenia o odstąpieniu.
            </li>
            <li>
              Sprzedawca gwarantuje zwrot ceny i kosztów uiszczonych przez Klienta, w tym kosztów dostarczenia sprzedanych Produktów Konsumentowi.
            </li>
            <li>
              Konsument ponosi odpowiedzialność za zmniejszenie wartości rzeczy będące wynikiem korzystania z niej w sposób wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania rzeczy.
            </li>
            <li>
              Wszelkie zwroty należności będą regulowane przez Sprzedawcę nie później niż w terminie 14 dni od otrzymania oświadczenia o odstąpieniu od Umowy, za pomocą takiego samego sposobu zapłaty, jakiego użył Konsument, chyba że Konsument wyraził zgodę na inny sposób zwrotu. Zwrot należności nie jest związany z dodatkowymi kosztami. Sprzedawca może wstrzymać się ze zwrotem należności otrzymanych przez Konsumenta do chwili otrzymania Produktu z powrotem.
            </li>
            <li>
              Sprzedawca gwarantuje Klientowi prawo do rezygnacji z Umowy w okresie 14 dni od dnia jej zawarcia, jeżeli Produkt nie satysfakcjonuje Klienta. Aby skorzystać z gwarancji satysfakcji wystarczy wysłanie oświadczenia na adres siedziby Sprzedawcy lub na adres e-mail podany w Regulaminie. W takim wypadku zastosowanie znajdą postanowienia z ust. 1-6 powyżej.
            </li>
            <li>
              Jeśli w ofercie występuje dodatkowa „Gwarancja Rezultatu”, Klientowi przysługuje prawo do rezygnacji z Umowy w przypadku braku 
              osiągnięcia obiecanych wyników. Aby skorzystać z „Gwarancji Rezultatu”, należy spełnić i udokumentować poniższe warunki oraz wysłać 
              oświadczenie na adres siedziby Sprzedawcy lub na adres e-mail podany w Regulaminie.
              Warunki wymagne do skorzystania z „Gwarancji Rezultatu”:
              <ul>
                <li>Klient wykona wszystkie zadania określone przez osoby prowadzące.</li>
                <li>Klient weźmie udział w minimum 95% spotkań online.</li>
                <li>Klient zapozna się ze wszystkimi materiałami wideo i wdroży u siebie zawarte w nich informacje.</li>
                <li>Klient udokumentuje, że jego wyniki były mniejsze od założonych w ofercie.</li>
              </ul>
            </li>
          </ol>

          <h3>§ 7 Odpowiedzialność</h3>
          <ol>
            <li>Sprzedawca ma obowiązek dostarczenia Produktu zgodnego z Umową.</li>
            <li>
              Jeżeli otrzymany Produkt w chwili dostarczenia jest niezgodny z Umową, Konsument może skorzystać z praw przysługujących mu w ramach odpowiedzialności Sprzedawcy za zgodność Produktu z Umową.
            </li>
            <li>
              Odpowiedzialność Sprzedawcy za zgodność Produktu z Umową obowiązuje przez okres dwóch lat od daty doręczenia Produktu. W przypadku gdy Umowa przewiduje dostarczanie Produktu w sposób ciągły, musi on pozostawać zgodny z Umową przez oznaczony w Umowie czas jej obowiązywania.
            </li>
            <li>Konsument może:
              <ol>
                <li>żądać doprowadzenia Produktu do jego zgodności z Umową;</li>
                <li>złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od Umowy, gdy</li>
                <ul>
                  <li>doprowadzenie Produktu do zgodności z Umową jest niemożliwe albo wymaga nadmiernych kosztów;</li>
                  <li>Sprzedawca nie doprowadził Produktu do zgodności z Umową zgodnie z pkt 1;</li>
                  <li>brak zgodności Produktu z Umową występuje nadal, mimo że Sprzedawca próbował doprowadzić Produkt do zgodności z Umową;</li>
                  <li>brak zgodności Produktu z Umową jest na tyle istotny, że uzasadnia obniżenie ceny albo odstąpienie od Umowy bez uprzedniego skorzystania ze środka ochrony określonego w pkt 1;</li>
                  <li>z oświadczenia Sprzedawcy lub okoliczności wyraźnie wynika, że nie doprowadzi on Produktu do zgodności z Umową w rozsądnym czasie lub bez nadmiernych niedogodności dla Konsumenta.</li>
                </ul>
              </ol>
            </li>
            <li>Sprzedawca ustosunkuje się do żądania Konsumenta niezwłocznie, nie później niż w terminie 14 dni, licząc od dnia otrzymania od pisma reklamacyjnego.</li>
            <li>O wyniku reklamacji Konsument zostanie poinformowany w taki sam sposób, w jaki przesłał reklamację.</li>
          </ol>

          <h3>§ 8 Warunki świadczenia usług</h3>
          <ol>
            <li>
              Sprzedawca świadczy na rzecz Klienta usługę drogą elektroniczną polegającą na umożliwieniu zawarcia Umowy o dostarczenie Produktów oraz korzystania z innych funkcjonalności Serwisu.
            </li>
            <li>Do zawarcia Umowy może nie być konieczne posiadanie konta w Serwisie.</li>
            <li>Świadczenie usługi dostępu do funkcjonalności Serwisu następuje nieodpłatnie. Nabycie Produktów dostępnych w Serwisie następuje odpłatnie.</li>
            <li>Do złożenia Zamówienia i skorzystania z funkcjonalności Serwisu konieczne jest:</li>
            <ul>
              <li>posiadanie aktywnego konta poczty elektronicznej (do dokonywania zakupów w Serwisie),</li>
              <li>przeglądarka internetowa uwzględniająca bieżące aktualizacje, z włączoną obsługą języka JavaScript,</li>
              <li>włączona obsługa wymaganych cookies w przeglądarce,</li>
              <li>dostęp do Internetu,</li>
              <li>zainstalowanie aplikacji umożliwiających odczytywanie plików PDF,</li>
              <li>możliwość odtwarzania materiałów wideo (do korzystania z niektórych Produktów elektronicznych).</li>
            </ul>
            <li>Jeżeli przeglądarka internetowa nie posiada możliwości obsługi plików audio, video, pdf., wtedy konieczna będzie:</li>
            <ul>
              <li>aktualna wersja standardowego odtwarzacza plików wideo (np. WindowsMediaPlayer),</li>
              <li>aktualna wersja standardowej przeglądarki plików .pdf (np. AdobeReader),</li>
            </ul>
            <li>Serwis wykorzystuje pliki cookies w celu zabezpieczenia bezpieczeństwa i wysokiego poziomu obsługi. 
              Użytkownik może w każdej chwili samodzielnie zmienić ustawienia dotyczące plików cookies – określić warunki ich 
              przechowywania i uzyskiwania dostępu przez pliki cookies do swojego urządzenia za pomocą ustawień przeglądarki internetowej 
              lub za pomocą konfiguracji usługi. Wyjaśnienia szczegółowe nt. plików cookies zawarto w Polityce Prywatności.</li>
            <li>Klient ma prawo złożyć reklamację dotyczącą funkcjonowania Serwisu, wysyłając wiadomość e-mail pod adres udostępniony w Regulaminie.</li>
            <li>Odpowiedź na reklamację zostanie wysłana w terminie 14 dni od jej otrzymania na dane z których została skierowana.</li>
            <li>Sprzedawca zastrzega sobie prawo do uprzedniej weryfikacji opinii/komentarzy zostawianych w ramach Serwisu w 
              oparciu o adres e-mail, a odnoszących się do zakupu lub używania opiniowanego/komentowanego Produktu. 
              Zweryfikowane opinie/komentarze posiadać będą  odpowiednią adnotację.</li>
          </ol>

          <h3>§ 9 Korzystanie z Serwisu i bezpieczeństwo cyfrowe</h3>
          <ol>
            <li>
              Zakazane jest dostarczanie przez Klienta treści o charakterze bezprawnym, w szczególności przesyłanie takich treści w ramach formularzy dostępnych w Serwisie, komentarzy, zapytań, wiadomości, w tym wiadomości e-mail.
            </li>
            <li>Klient zobowiązany jest korzystać z Serwisu w sposób zgodny z prawem, Regulaminem i dobrymi obyczajami, a w szczególności:</li>
            <ul>
            <li>korzystać z Serwisu w sposób niezakłócający korzystanie z Serwisu przez pozostałych, nienaruszający jakichkolwiek praw, dóbr lub interesów osób trzecich, niewpływający negatywnie na działanie Serwisu, szczególnie poprzez wykorzystanie złośliwego oprogramowania;</li>
            <li>nie udostępniać danych dostępowych do swojego konta w Serwisie jakimkolwiek osobom trzecim nieupoważnionym;</li>
            <li>nie rozpowszechniać Produktów ani jego poszczególnych fragmentów bez uprzedniej zgody Sprzedawcy, poza dozwolonym użytkiem własnym.</li>
            </ul>
            <li>Sprzedawca zastrzega sobie prawo do natychmiastowego zablokowania działania Konta Klienta w przypadkach stwierdzenia łamania warunków Regulaminu lub przepisów prawa, a także działania niezgodnie z dobrymi obyczajami. </li>
            <li>Ze względu na niezależne od Sprzedawcy właściwości sieci Internet oraz sprzętu komputerowego Klienta, Klient jest świadomy ryzyk z tym związanych, w szczególności braku gwarancji nieprzerwanego i niezakłóconego dostępu do Serwisu.</li>
            <li>Sprzedawca zastrzega sobie prawo czasowych przerw w działaniu Serwisu w celach konserwacyjnych, naprawczych.</li>
            <li>Sprzedawca podejmuje starania, aby poinformować Klienta ze stosownym wyprzedzeniem o planowanych przerwach technicznych w świadczeniu usług lub dostępu do Serwisu.</li>
            <li>Sprzedawca dba o bezpieczeństwo cyfrowe w obrębie Serwisu przy jednoczesnym respektowaniu prawa użytkowników do korzystania z funkcjonalności Serwisu oraz do wyrażania własnego zdania, czy postępowania zgodne z własnymi wyborami. </li>
            <li>Treści oraz funkcjonalności dostępne w Serwisie nie są prezentowane w oparciu o dedykowane funkcjonalności pozwalające na personalizowanie treści.</li>
            <li>Wszystkie wybory dokonywane w Serwisie są podejmowane w oparciu o samodzielne decyzje użytkownika.</li>
            <li>Sprzedawca nie korzysta z algorytmów w procesie podejmowania decyzji w ramach Serwisu.</li>
            <li>Reklamy wyświetlane będą zgodnie z wyborami użytkownika w zakresie przetwarzania plików cookies lub ustawień przeglądarki. W sytuacji, gdy użytkownik wyraził zgodę na personalizację treści, prezentowane reklamy będą dostosowane do preferencji użytkownika określonych na podstawie jego aktywności w Serwisie. W przypadku, braku zgody użytkownika na personalizację treści reklamowych, wyświetlane reklamy nie będą personalizowane i opierają się na wyświetleniach reklamowych przygotowanych dla ogółu użytkowników.</li>
            <li>Każdy użytkownik odwiedzający i korzystający z Serwisu ma możliwość wysłać Sprzedawcy zgłoszenie dotyczące treści w ramach Serwisu niezgodnych z prawem lub naruszających Regulamin poprzez skierowanie wiadomości e-mail na adres wyznaczony jako punkt kontaktowy, wskazany w §2 pkt 8 Regulaminu z dopiskiem – „dot. naruszenia zasad bezpieczeństwa cyfrowego”. Osoba zgłaszająca powinna, jeżeli jest to możliwe, przedstawić informacje, które pozwalają na weryfikację zgłoszenia naruszenia, w szczególności wyjaśnienie przyczyn, z powodu których osoba zgłaszająca uważa daną treść za niezgodną z prawem lub sprzeczną z Regulaminem oraz swoje dane kontaktowe, na które ma zostać udzielona odpowiedź.</li>
            <li>Za niedozwolone treści w ramach Serwisu uważa się treści godzące w prawa osób trzecich, treści sprzeczne z prawem, propagujące przemoc, dyskryminację, nierówne traktowanie, o charakterze rasistowskim lub w innych sposób godzące w dobra osobiste.</li>
            <li>Sprzedawca zastrzega prawo do zawieszenia użytkownika lub moderacji treści opublikowanych w Serwisie na wypadek naruszenia powyższą treścią prawa lub Regulaminu, w tym uwzględniając korzystanie z botów rejestrujących konto, kierowanie żądań zapłaty bezprawnie, wysyłanie wiadomości stanowiących przejaw phishingu, phishing danych osobowych, podawania fałszywych informacji lub informacji naruszających prawa osób trzecich. </li>
            <li>Moderacja treści przez Sprzedawcę przejawiać się może poprzez:</li>
            <ul>
              <li>odmowę publikacji treści,</li>
              <li>usunięcie treści,</li>
              <li>uniemożliwienie dostępu do treści,</li>
              <li>depozycjonowanie treści,</li>
              <li>zawieszenie lub zakończenie świadczenia usługi na rzecz użytkownika,</li>
              <li>zawieszenie lub zamknięcie Konta Klienta.</li>
            </ul>
            <li>Decyzja opierać się będzie w oparciu o ocenę sytuacji i okoliczności w zgodzie z obowiązującymi przepisami prawa. Każda sprawa będzie analizowana i oceniana indywidualnie bez użycia procesów automatycznych. </li>
            <li>Każda osoba co do której będzie podejmowana decyzja przez Sprzedawcę zostanie powiadomiona wraz z uzasadnieniem. </li>
            <li>Na wypadek niezgadzania się przez użytkownika z decyzją, użytkownik uprawniony jest do odwołania się w ciągu 14 dni od doręczenia decyzji, z obowiązkiem uzasadnienia stanowiska użytkownika. Sprzedawca udzieli odpowiedzi na odwołanie w ciągu 14 dni. Stanowisko Sprzedawcy w odpowiedzi na odwołanie jest ostateczne. </li>
            <li>Użytkownik posiada również prawo do rozstrzygnięcia kwestii bezpieczeństwa danych w Serwisie za pośrednictwem odpowiedniego podmiotu posiadającego certyfikat wydany przez Koordynatora Usług Elektronicznych. </li>
            <li>Każda osoba dokonująca zgłoszenia podlega ochronie prawnej, a jej dane i wymiana korespondencji ze Sprzedawcą podlega poufności bez prawa dostępu osób nieupoważnionych.</li>
          </ol>

          <h3>§ 10 Ochrona danych osobowych</h3>
          <ol>
            <li>Sprzedawca oświadcza, że wszelkie dane przechowywane w Serwisie wprowadzone przez Klienta w ramach korzystania z Produktu, będą chronione przez zabezpieczenie przed ingerencją nieuprawnionych osób zgodnie z polskim prawem.</li>
            <li>Dane osobowe są zbierane i przetwarzane przede wszystkim w celu realizacji Umowy.</li>
            <li>Szczegółowe zasady przetwarzania danych osobowych oraz wykorzystania plików cookies opisane są w <a href="https://kursy.inmyailab.pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">Polityce Prywatności</a>.</li>
          </ol>

          <h3>§ 11 Postanowienia Końcowe</h3>
          <ol>
            <li>W celu uniknięcia wątpliwości stwierdza się, że żadne z postanowień niniejszego Regulaminu nie ogranicza uprawnień Konsumenta, jakie przysługują mu na podstawie przepisów obowiązującego na terytorium Rzeczypospolitej Polskiej prawa. W przypadku stwierdzenia istnienia postanowienia o takim charakterze zastosowanie znajdują przepisy obowiązującego na terytorium Rzeczypospolitej Polskiej prawa, a w szczególności kodeksu cywilnego oraz ustawy o prawach konsumenta.</li>
            <li>Rozstrzygnięcie ewentualnych sporów powstałych pomiędzy Sprzedawcą a Konsumentem, zostaje poddane sądom właściwym zgodnie z postanowieniami właściwych przepisów kodeksu postępowania cywilnego, przy czym Sprzedawca dopuszcza drogę postępowania mediacyjnego. Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego działającego przy Inspekcji Handlowej lub do wojewódzkiego inspektora Inspekcji Handlowej, a także może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między Konsumentem a Sprzedawcą, korzystając z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów.</li>
            <li>Konsument może również skorzystać z platformy ODR, która dostępna jest pod adresem <a href="http://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">http://ec.europa.eu/consumers/odr</a>.  Platforma służy rozstrzyganiu sporów pomiędzy konsumentami i przedsiębiorcami dążącymi do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o świadczenie usług.</li>
            <li>Rozstrzygnięcie ewentualnych sporów powstałych pomiędzy Sprzedawcą a Klientem, który nie jest Konsumentem, zostaje poddane sądowi właściwemu ze względu na siedzibę Sprzedawcy.</li>
            <li>Sprzedawca zastrzega sobie prawo do zmiany Regulaminu. Wszelkie zmiany Regulaminu wchodzą w życie w terminie wskazanym przez Sprzedawcę. Zamówienia złożone przed datą wejścia w życie zmian do niniejszego Regulaminu są realizowane na podstawie postanowień obowiązujących w dniu złożenia Zamówienia.</li>
          </ol>
        </Paper>
      </div>
      <Footer/>
      </>
    </React.Fragment>
  );
}
