import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function Hero() {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    beforeChange: (current, next) => setActiveSlide(next)
  };

  return (
    <React.Fragment>
      <Box className="Hero" sx={{ width: '100%', overflow: 'hidden' }}>
        <Slider {...settings} style={{ width: '100%' }}>
          <div className="slide slide-1" style={{ width: '100%' }}>
            <div className="content">
              <h1>Twórz profesjonalne produkty cyfrowe szybciej, sprawniej i bardziej efektywnie dzięki sztucznej inteligencji!</h1>
            </div>
          </div>

          <div className={`slide slide-2 ${activeSlide === 1 ? 'animate' : ''}`} style={{ width: '100%' }}>
            <div className="content">
              <h1>Jak AI zmienia proces tworzenia treści?</h1>
            </div>
          </div>

          <div className="slide slide-3" style={{ width: '100%' }}>
            <div className="content">
              <div className="content-text">
                <h2><span>Fakt #1:</span> Skrócenie czasu pracy o 40%</h2>
                <p className="text">Badania pokazują, że narzędzia AI, takie jak ChatGPT, mogą skrócić czas pisania i edytowania treści nawet o 40%.</p>
                <div className="source">
                  <p><span> Źródło:</span> Noy, S., & Zhang, W. (2023). Experimental evidence on the productivity effects of generative artificial intelligence.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="slide slide-4" style={{ width: '100%' }}>
            <div className="content">
              <div className="content-text">
                <h2><span>Fakt #2:</span> Zwiększenie produktywności o 30%</h2>
                <p className="text">Dzięki automatyzacji zadań, takich jak generowanie treści, korekta gramatyki, a nawet tworzenie grafik i multimediów, twórcy mogą zwiększyć swoją produktywność o około 30%.</p>
                <div className="source">
                  <p><span> Źródło:</span> Amado-Salvatierra, H. R., Morales Chan, M., & Hernández, R. (2023). Combining human creativity and AI-based tools in the instructional design of MOOCs.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="slide slide-5" style={{ width: '100%' }}>
            <div className="content">
              <div className="content-text">
                <h2><span>Fakt #3:</span> Poprawa jakości o 18%</h2>
                <p className="text"> Badania pokazują, że wykorzystanie AI do automatycznego wprowadzania poprawek, ulepszania struktury tekstu oraz optymalizacji pod kątem SEO prowadzi do wzrostu jakości treści o około 18%. </p>
                <div className="source">
                  <p><span> Źródło:</span> Noy, S., & Zhang, W. (2023). Experimental evidence on the productivity effects of generative artificial intelligence.</p>
                </div>
              </div>
            </div>
          </div>
          
        </Slider>
        <style>{`
          .slick-dots {
            bottom: -53px !important;
          }
        `}</style>
      </Box>
    </React.Fragment>
  );
}
