import React from 'react';
import Paper from '@mui/material/Paper';

export function Offer() {
  const handleScrollToCourses = () => {
    const kursyButton = document.getElementById('KursyButton');
    if (kursyButton) {
      kursyButton.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <React.Fragment>
      <div className="Offer">
        <Paper className="Offer-paper">
          <h2>Oferta</h2>
          <p>Tworzę produkty cyfrowe, które pomagają w pełni wykorzystać potencjał narzędzi AI w pracy. </p>
          <p>Jako twórca treści cyfrowych przetestowałam wiele narzędzi AI, które zautomatyzowały moją pracę, 
              pozwalając osiągać lepsze wyniki w krótszym czasie. </p>
          <p>Teraz dzielę się swoją wiedzą, aby każdy mógł korzystać z nowoczesnych rozwiązań technologicznych. </p>
          <p>Nie musisz już tracić czasu na ręczne tworzenie treści — dzięki moim rozwiązaniom możesz 
              pracować efektywniej, niezależnie od etapu, na którym obecnie jesteś w procesie tworzenia produktów cyfrowych. </p>
          <p>Jeśli jeszcze tego nie zrobiłeś/aś, koniecznie sprawdź zakładkę 
            <span style={{ color: 'var(--color-main)', fontWeight: "700", cursor: 'pointer' }} onClick={handleScrollToCourses}> Kursy </span> 
            na górze strony.</p>
          <p>Anna Stępień</p>
          <p>właścicielka AStep_Lab</p>
        </Paper>
      </div>
    </React.Fragment>
  );
}
