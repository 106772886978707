import React from "react";
import { ResponsiveAppBarLight } from "./header-light";
import Paper from '@mui/material/Paper';


export const PageNotFound = () => {
    return (
            <React.Fragment>
              <>
              <ResponsiveAppBarLight/>
              <div className="PageNotFound">
                <Paper style={{width: "80%", height: "80%", backgroundColor: "var(--bgcolor-secondary)"}}>
                <h1>Wygląda na to, że to ślepa uliczka!</h1>
                <p>Strona, której szukasz, nie istnieje lub została przeniesiona.</p>
                <p>Skorzystaj z menu, aby znaleźć to, czego potrzebujesz.</p>
                </Paper>
              </div>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "70px", backgroundColor: "var(--bgcolor-header)", color: "var(--color-secondary)"}}>
                <p>© 2024 AStep_Lab <span>| Wszystkie prawa zastrzeżone</span></p>
              </div>
              </>
            </React.Fragment>
    );
}
