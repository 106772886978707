import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export function CookieConsent() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  
  // localStorage.clear();

  useEffect(() => {
    const accepted = localStorage.getItem('cookiesAccepted');
    if (accepted) {
      setCookiesAccepted(true);
    }
    setIsInitialized(true);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
  };

  if (!isInitialized) {
    return null;
  }

  if (cookiesAccepted) {
    return null;
  }

  return (
    <Box sx={{ 
      position: 'fixed', 
      bottom: 0, 
      width: '100%', 
      backgroundColor: 'var(--color-logo)', 
      color: '#fff', 
      textAlign: 'center', 
      zIndex: "10"
    }}>
      <div style={{paddingLeft: "30px", paddingRight: "30px", paddingBottom: "40px"}}>
        <h3 style={{ fontFamily: 'var(--font-body)', color: "#000000" }}>Szanuję Twoją prywatność!</h3>
        <p style={{ fontFamily: 'var(--font-body)', color: "#000000" }}>
          Zależy mi, aby ta strona działała jak najlepiej, szybko i intuicyjnie, dlatego korzystam z plików cookies, 
          czyli "ciasteczek". 
          Dzięki nim mogę dostosowywać treści do Twoich potrzeb oraz analizować ruch na stronie. 
          Więcej na ten temat możesz dowiedzieć się z <a href="https://www.asteplab.pl/polityka-prywatnosci" 
          style={{textDecoration: "none", color: "var(--color-main)", fontWeight: "700"}}> Polityki Prywatności</a> tej strony.
          Pamiętaj, że możesz w dowolnym momencie zmienić ustawienia, dotyczące ciasteczek w swojej przeglądarce. 
          Klikając „Zgadzam się”, pozwalasz mi na ich użycie.
        </p>
        <Button 
          className="Cookies-btn" 
          variant="contained" 
          sx={{
            backgroundColor: 'var(--bgcolor-header)',
            color: 'var(--color-secondary)',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            fontFamily: 'var(--font-body)',
            fontWeight: 700,
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
            marginTop: '20px',
            '&:hover': {
              backgroundColor: 'var(--button-hover)',
              color: 'var(--bgcolor-header)',
            },
          }}
          onClick={handleAcceptCookies}
        >
          Zgadzam się
        </Button>
      </div>
    </Box>
  );
}
