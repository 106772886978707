import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB_MLtktSQMqc8wa8OjveS-ynGB3hlUbjM",
  authDomain: "asteplab.firebaseapp.com",
  projectId: "asteplab",
  storageBucket: "asteplab.appspot.com",
  messagingSenderId: "949800537071",
  appId: "1:949800537071:web:feafcd769ca06fe3f3841a",
  measurementId: "G-J62TPNQR16"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);