import React from 'react';
import { useForm } from 'react-hook-form';
import { db } from '../firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';



export function Contact() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = async (data) => {
    try {

      await addDoc(collection(db, 'contacts'), {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        message: data.message,
        timestamp: new Date(),
      });


      const emailParams = {
        from_name: `${data.firstName} ${data.lastName}`, 
        from_firstName: data.firstName,
        email: data.email,
        message: data.message,
      };

      emailjs.send(
        'service_lkipbd5',
        'template_wr7irah',
        emailParams,
        'xm-q7J-aDYgW00sac'
      ).then(() => {
        alert('Wiadomość wysłana pomyślnie!');
        reset();
      }, (error) => {
        console.error('Błąd przy wysyłaniu e-maila: ', error);
        alert('Wystąpił problem przy wysyłaniu wiadomości e-mail.');
      });
    } catch (error) {
      console.error('Błąd przy wysyłaniu formularza: ', error);
      alert('Wystąpił problem przy wysyłaniu wiadomości.');
    }
  };

  return (
    <React.Fragment>
      <div id="Contact">
      <div className="Contact-box">
        <h2 style={{ fontFamily: 'var(--font-body)', color: "var(--color-main)" }}>Formularz kontaktowy</h2>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>

          <div className="Contact-box-item" style={{ fontFamily: 'var(--font-body)', color: "var(--color-main)" }}>
            <label>Imię:</label>
            <input
              {...register('firstName', { required: 'Imię jest wymagane' })}
              placeholder="Podaj swoje imię"
            />
            {errors.firstName && <p style={{ color: 'red' }}>{errors.firstName.message}</p>}
          </div>


          <div className="Contact-box-item" style={{ fontFamily: 'var(--font-body)', color: "var(--color-main)" }}>
            <label>Nazwisko:</label>
            <input
              {...register('lastName', { required: 'Nazwisko jest wymagane' })}
              placeholder="Podaj swoje nazwisko"
            />
            {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName.message}</p>}
          </div>


          <div className="Contact-box-item" style={{ fontFamily: 'var(--font-body)', color: "var(--color-main)" }}>
            <label>E-mail:</label>
            <input
              {...register('email', {
                required: 'E-mail jest wymagany',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Podaj poprawny adres e-mail',
                },
              })}
              placeholder="Podaj swój e-mail"
            />
            {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
          </div>

          <div className="Contact-box-item" style={{ fontFamily: 'var(--font-body)', color: "var(--color-main)" }}>
            <label>Wiadomość:</label>
            <textarea
              {...register('message', { required: 'Wiadomość jest wymagana' })}
              placeholder="Tutaj wpisz wiadomość"
              rows="5"
            />
            {errors.message && <p style={{ color: 'red' }}>{errors.message.message}</p>}
          </div>

          <div style={{ fontFamily: 'var(--font-body)', color: "var(--color-main)", display: "flex", justifyContent: "flex-end"}}>
            <button type="submit">Wyślij wiadomość</button>
          </div>
        </form>
      </div>
      </div>
    </React.Fragment>
  );
}
